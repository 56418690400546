:root {
  --cl-background: #263238;

  background-color: var(--cl-background);
  color: #fff;
}

body {
  margin: 0;
  overscroll-behavior: none;
}

canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  touch-action: none;
}

#menu {
  position: absolute;
  left: 50vw;
  top: 50vh;
  transform: translateX(-50%) translateY(-50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 14rem;
}

#menu[hidden] {
  display: none;
}

#menu > * + * {
  margin-top: 1rem;
}

.line {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.line > input {
  width: 5rem;
}

#algorithm {
  text-transform: capitalize;
}

#menu > button {
  margin-top: 2rem;
}

.social {
  position: absolute;
  right: 2.5rem;
  bottom: 1.5rem;
}

#menu[hidden] ~ .social {
  display: none;
}

.social svg {
  width: 1.3rem;
  height: 1.3rem;
}

figure {
  position: absolute;
  left: 2.5rem;
  bottom: 1.5rem;
  margin: 0;
  max-width: 60ch;

  font-family: Iowan Old Style, Apple Garamond, Baskerville, Times New Roman,
    Droid Serif, Times, Source Serif Pro, serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol;
  color: #fffd;
  font-size: 14px;
}

:root:not([data-algo='quick']) figure {
  display: none;
}

figure q {
  line-height: 1.5;
  margin: 0;
}

figure a {
  color: unset;
  text-decoration: none;
}

figcaption {
  margin-top: 0.8em;
  margin-left: 1em;
  font-family: inherit;
  font-size: 1em;
  color: #fffb;
}

figcaption::before {
  content: '\2015';
}
